import React, { useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { space, layout, compose } from "styled-system"

import Card from "components/atoms/Card"
import Flex from "components/atoms/Flex"
import PortfolioCompanyModal from "components/molecules/PortfolioCompanyModal"

const ProportionalCard = styled(Card)`
  position: relative;
  width: 100%;
  box-shadow: none;
  border: none;
  &:after {
    content: "";
    display: block;
    padding-bottom: 73.6842%;
  }
`

const Content = styled("div")`
  position: absolute;
  height: 100%;
  width: 100%;
`

const StyledButton = styled.button`
  width: 100%;
  max-width: 304px;
  .logo {
    transition: transform 0.3s ease;
  }
  &:hover {
    .logo {
      transform: scale(1.08);
    }
  }
`

const StyledImage = styled(Img)(compose(space, layout))

const LogoItem = company => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <StyledButton onClick={() => setModalOpen(true)}>
      <ProportionalCard bg="white" width="100%" mx="auto">
        <Content>
          <Flex justifyContent="center" alignItems="center" height="100%">
            <StyledImage
              className="logo"
              fluid={company.logo.fluid}
              alt={company.name || ""}
              maxHeight={{ _: "100px", sm: "100px" }}
              style={{ width: "100%", maxWidth: "200px" }}
              imgStyle={{ objectFit: "contain" }}
            />
          </Flex>
        </Content>
        <PortfolioCompanyModal
          {...company}
          display={modalOpen}
          close={() => setModalOpen(false)}
        />
      </ProportionalCard>
    </StyledButton>
  )
}

export default LogoItem
