import React from "react"
import Img from "gatsby-image"
import Modal from "components/atoms/Modal"
import styled from "styled-components"

import Box from "components/atoms/Box"
import Button from "components/atoms/Button"

import { colors } from "utils/styled-constants"

const StyledModal = styled(Modal)`
  width: 96%;
  max-width: 576px;
  background-color: white;
  box-sizing: border-box;
`

const Paragraph = styled.p`
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  white-space: pre-line;
  color: #34486b;
  margin: 0;
`

const TitleDiv = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #34486b;
  margin: 0;
  margin-bottom: 8px;
`

const LinkText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.skyBlue};
`

const Logo = styled(Img)`
  max-width: 200px;
  max-height: 100px;
  img {
    object-fit: contain !important;
    object-position: left center !important;
  }
`

const PortfolioCompanyModal = ({
  logo,
  name,
  description,
  websiteLink,
  ...rest
}) => {
  return (
    <StyledModal {...rest}>
      <Box p={{ _: "30px 20px 30px", md: "48px", lg: "48px 92px 48px 48px" }}>
        <Box overflow="hidden" mb={7}>
          <Logo fluid={logo.fluid} alt={logo.description} />
        </Box>
        <TitleDiv>{name}</TitleDiv>
        <Paragraph>{description.description}</Paragraph>
        <Box mt={6}>
          <a href={websiteLink} target="_blank" rel="noreferrer noopener">
            <Button color="skyBlue" text="View Website" variant="link" />
          </a>
        </Box>
      </Box>
    </StyledModal>
  )
}

export default PortfolioCompanyModal

export const query = graphql`
  fragment PortfolioCompany on ContentfulPortfolioCompany {
    name
    logo {
      description
      fluid(maxWidth: 200, quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    description {
      description
    }
    websiteLink
    tags {
      label
    }
  }
`
