import React, { useContext } from "react"
import ReactReveal from "react-reveal/Reveal"
import styled, { ThemeContext } from "styled-components"
import { animation } from "utils/styled-helpers"
import {
  fade,
  fadeUp,
  fadeLeft,
  fadeRight,
  fadeBgLeft,
  fadeBgRight,
  fadeBgDown,
  accentZoom,
} from "styles/reveal-keyframes"

// Used to apply styles to the .react-reveal div
// https://github.com/styled-components/styled-components/issues/2113
const RevealWrapper = styled.div`
  height: ${props => props.height || ""};
  & .react-reveal {
    height: ${props => props.height || ""};
    visibility: hidden;
    animation-fill-mode: forwards;
    ${animation}
  }
  & .react-reveal.none {
    visibility: visible;
  }
  & .react-reveal.fade {
    visibility: visible;
    animation-name: ${fade};
  }
  & .react-reveal.fade-up {
    visibility: visible;
    animation-name: ${fadeUp};
  }
  & .react-reveal.fade-left {
    visibility: visible;
    animation-name: ${fadeLeft};
  }
  & .react-reveal.fade-right {
    visibility: visible;
    animation-name: ${fadeRight};
  }
  & .react-reveal.fade-bg-right {
    animation-name: ${fadeBgRight};
  }
  & .react-reveal.fade-bg-left {
    animation-name: ${fadeBgLeft};
  }
  & .react-reveal.fade-bg-down {
    animation-name: ${fadeBgDown};
  }
  & .react-reveal.accent-zoom {
    animation-name: ${accentZoom};
  }
`

/*
 * Uses the reveal effect defined in the theme
 */
const Reveal = ({ children, timingFunction, effect, height, ...rest }) => {
  // Get reveal effect and durations from theme
  const { reveal, durations } = useContext(ThemeContext)

  // Apply defaults if effect, duration, or timingFunction isn't defined in theme
  const revealEffect = effect || reveal?.effect || "none"
  const revealDuration = durations[reveal?.duration] || durations["md"]
  const revealTimingFunction =
    timingFunction || reveal?.timingFunction || "ease"

  return (
    <RevealWrapper
      height={height}
      animationTimingFunction={revealTimingFunction}
    >
      <ReactReveal
        style={height ? { height: "100%" } : null}
        effect={revealEffect}
        duration={revealDuration}
        {...rest}
      >
        {children}
      </ReactReveal>
    </RevealWrapper>
  )
}

export default Reveal
