import React from "react"
import styled from "styled-components"
import Box from "components/atoms/Box"
import TenCovesIcon from "images/ten-coves-icon.svg"

const Container = styled(Box)`
  position: absolute;
  z-index: -1;
  & > svg {
    width: 100%;
    height: 100%;
  }
`

const BackgroundIcon = props => {
  return (
    <Container {...props}>
      <TenCovesIcon />
    </Container>
  )
}

export default BackgroundIcon
