import { keyframes } from "styled-components"

// Different keyframe animations
export const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
`
export const fadeRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
`

export const fadeLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
`

export const fadeBgLeft = keyframes`
0% {
  visibility: visible;
  opacity: 0;
  transform: translateX(-30%);
}
100% {
  visibility: visible;
  opacity: 1;
  transform: none;
}
`

export const fadeBgRight = keyframes`
0% {
  visibility: visible;
  opacity: 0;
  transform: translateX(30%);
}
100% {
  visibility: visible;
  opacity: 1;
  transform: none;
}
`

export const fadeBgDown = keyframes`
0% {
  visibility: visible;
  opacity: 0;
  transform: translateY(-50px);
}
100% {
  visibility: visible;
  opacity: 1;
  transform: none;
}
`

export const accentZoom = keyframes`
0% {
  visibility: visible;
  opacity: 0;
  transform: scale(0.5);
}
100% {
  visibility: visible;
  opacity: 1;
  transform: none;
}
`
