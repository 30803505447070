import React, { useRef } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"

import Reveal from "components/utils/Reveal"

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  & > div {
    height: 100%;
  }
`

const StyledImg = styled(Img)`
  position: relative;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`

// const StyledImg = styled(Img)`
//   transform: translateX(
//     ${props =>
//       props.right ? (-props.height * 0.81) / 2 : (props.height * 0.81) / 2}px
//   );
//   height: ${props => props.height}px;
// `

const SideBackgroundImage = ({ first, image, left, right }) => {
  let effect = left ? "fade-bg-left" : "fade-bg-right"
  return (
    <Wrapper right={right}>
      <Reveal
        effect={effect}
        timeout={1500}
        delay={first ? 800 : 0}
        height="100%"
        // timingFunction="cubic-bezier(0,1.01,.36,1)"
        timingFunction="cubic-bezier(.17,.73,.36,1)"
      >
        <StyledImg fluid={image.fluid} right={right} />
      </Reveal>
    </Wrapper>
  )
}

export default SideBackgroundImage
