import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import { Title, Paragraph, Label } from "components/atoms/Typography"
import Box from "components/atoms/Box"
import Button from "components/atoms/Button"

import { colors } from "utils/styled-constants"

const SectionText = ({
  label,
  title,
  subtitle,
  subtitleColor,
  subtitleFontStyle,
  white,
  variant,
  as,
  description,
  align,
  color,
  teamMemberPage,
  ...rest
}) => {
  let titleArray = null
  if (title) {
    titleArray = title.split(" ").map((word, idx) => (
      <>
        <span style={{ display: "inline-block" }} key={idx}>
          {word}
        </span>{" "}
      </>
    ))
    const pIdx = titleArray.length - 2 // penultimate item idx
    titleArray[pIdx] = (
      <span style={{ display: "inline-block" }}>
        {titleArray[pIdx]}
        {titleArray.pop()}
      </span>
    )
  }
  return (
    <Box
      color={white ? "white" : colors.darkBlue}
      mx={align === "center" ? "auto" : ""}
      {...rest}
    >
      {label && (
        <Label textAlign={align} mb="18px" color="inherit">
          {label}
        </Label>
      )}
      {title && (
        <Title
          mb={description ? 0 : "50px"}
          variant={variant || "h2"}
          as={variant || "h2"}
          textAlign={align}
          color="inherit"
        >
          {titleArray}
        </Title>
      )}
      {subtitle && (
        <Paragraph
          textAlign={align}
          color="inherit"
          fontWeight={500}
          fontSize="15px"
          mt={3}
        >
          {subtitle}
        </Paragraph>
      )}
      {description && (
        <Paragraph
          mt={"14px"}
          textAlign={align}
          color="inherit"
          fontSize={teamMemberPage ? "21px" : undefined}
          lineHeight="28px"
          markdown
          dangerouslySetInnerHTML={{
            __html: `<div> ${description.childMarkdownRemark.html} </div>`,
          }}
        />
      )}
    </Box>
  )
}

SectionText.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["h1", "h2"]),
  as: PropTypes.oneOf(["h1", "h2"]),
  description: PropTypes.string,
  align: PropTypes.oneOf(["left", "center"]),
  color: PropTypes.oneOf(["white", "beige", "darkBlue", "mediumBlue"]),
  ...Box.propTypes,
  ...Button.propTypes,
}

SectionText.defaultProps = {
  color: "darkBlue",
  variant: "h2",
  align: "left",
}

export default SectionText

export const query = graphql`
  fragment SectionText on ContentfulSectionText {
    label
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    subtitle
    align
    color
  }
`
