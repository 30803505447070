import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const OuterWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: white;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  box-shadow: ${props =>
    props.boxShadow ? props.boxShadow : "-9px 21px 60px #ebf0f4"};
  box-sizing: border-box;
`

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  padding-top: 50%;
  transform: translateX(50%);
  width: 100%;
`

const StyledImg = styled(Img)`
  display: block;
  transform: translateX(-50%) translateY(-50%);
  width: 70%;
  height: auto;
`

const CircleLogo = ({ logo, boxShadow }) => {
  return (
    <OuterWrapper boxShadow={boxShadow}>
      <InnerWrapper>
        <StyledImg fluid={logo.fluid} />
      </InnerWrapper>
    </OuterWrapper>
  )
}

export default CircleLogo
