import React from "react"
import Box from "components/atoms/Box"

const Card = props => <Box className="card" {...props} />

Card.defaultProps = {
  borderRadius: "",
  boxShadow: "",
}

Card.propTypes = {
  ...Box.propTypes,
}

export default Card
