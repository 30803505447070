import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import styled from "styled-components"
import { color } from "styled-system"
import { shouldForwardProp } from "utils/styled-helpers.js"
import Img from "gatsby-image"

import SideBackgroundImage from "components/molecules/SideBackgroundImage"

import Reveal from "components/utils/Reveal"
import Fade from "react-reveal/Fade"

import { headerHeight } from "utils/styled-constants"

const StyledSection = styled("section").withConfig({ shouldForwardProp })`
  top: ${props => (props.first ? `-${headerHeight}` : "")};
  padding-top: ${props => (props.first ? `${headerHeight}` : "")};
  margin-bottom: ${props => (props.first ? `-${headerHeight}` : "")};
  overflow: hidden;
  ${props =>
    props.minHeight
      ? `
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `
      : ""}
  ${color};
`

const AccentImageContainer = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AccentImage = styled.div`
  width: 100%;
  max-width: 1100px;
  overflow: hidden;
  .image {
    position: relative;
    height: 100%;
  }
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    .react-reveal {
      width: 900px;
    }
  }
`

const BackgroundImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  ${"" /* height: ${props => (props.first ? `calc(100% + ${headerHeight})` : "100%")}; */}
  ${"" /* padding-top: ${props => (props.first ? `${headerHeight}` : "")}; */}
  overflow: hidden;
  left: 0;
  ${"" /* top: ${props => (props.first ? `-${headerHeight}` : 0)}; */}
  top: 0;
  z-index: 0;
`

const BackgroundImage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`

const StyledBackgroundImg = styled(Img)`
  width: 100%;
  height: 100%;
  min-width: 1500px;
  img {
    min-width: 1500px;
  }
`

const SectionStyling = ({
  backgroundColor,
  accentImage,
  backgroundImage,
  leftBackgroundImage,
  rightBackgroundImage,
  animateFrom,
  children,
  first,
  activeSection,
  ...rest
}) => {
  const [showAccent, setShowAccent] = useState(false)

  useEffect(() => {
    if (activeSection) {
      setShowAccent(true)
    }
  }, [activeSection])

  const invertColor =
    backgroundColor === "mediumBlue" || backgroundColor === "darkBlue"
  rest.bg = `${backgroundColor}`

  const direction = animateFrom ? animateFrom : "right"

  return (
    <StyledSection
      first={first}
      style={{ position: "relative" }}
      backgroundColor
      {...rest}
    >
      {backgroundImage && (
        <BackgroundImageWrapper first={first}>
          <BackgroundImage>
            <StyledBackgroundImg fluid={backgroundImage.fluid} />
          </BackgroundImage>
        </BackgroundImageWrapper>
      )}
      {leftBackgroundImage && (
        <SideBackgroundImage first={first} image={leftBackgroundImage} left />
      )}
      {rightBackgroundImage && (
        <SideBackgroundImage first={first} image={rightBackgroundImage} right />
      )}
      {accentImage && (
        <AccentImageContainer>
          <AccentImage>
            <div className="image">
              <div className="imageInner">
                {animateFrom === "center" && (
                  <Reveal
                    when={showAccent}
                    effect="accent-zoom"
                    timeout={1400}
                    fraction={0.5}
                  >
                    <Img fluid={accentImage.fluid} />
                  </Reveal>
                )}
                {animateFrom !== "center" && (
                  <Fade
                    className="reveal"
                    left={animateFrom === "left"}
                    right={animateFrom === "right"}
                    top={animateFrom === "top"}
                    bottom={animateFrom === "bottom"}
                    distance="50px"
                    duration={1500}
                    fraction={0.75}
                  >
                    <Img fluid={accentImage.fluid} />
                  </Fade>
                )}
              </div>
            </div>
          </AccentImage>
        </AccentImageContainer>
      )}
      <div style={{ position: "relative", zIndex: 10 }}>{children}</div>
    </StyledSection>
  )
}

SectionStyling.defaultProps = {
  backgroundColor: "white",
}

SectionStyling.propTypes = {
  children: PropTypes.node.isRequired,
  ...propTypes.color,
}

export default SectionStyling

// removed backgroundimage from query

export const query = graphql`
  fragment SectionStyling on ContentfulSectionStyling {
    backgroundColor
    backgroundImage {
      fluid(maxWidth: 1500, quality: 100) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    leftBackgroundImage {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    rightBackgroundImage {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    accentImage {
      fluid(quality: 90) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    animateFrom
    isDarkBackground
  }
`
