import React from "react"
import createReactClass from "create-react-class"
import ReactDOM from "react-dom"
import { ThemeProvider } from "styled-components"
import { theme } from "utils/styled-constants"

import classNames from "classnames"
import CloseIcon from "images/modal-close.svg"

import styles from "styles/components/atoms/modal.module.scss"

var Portal = createReactClass({
  render: () => null,
  portalElement: null,
  componentDidMount() {
    var p = this.props.portalId && document.getElementById(this.props.portalId)
    if (!p) {
      var p = document.createElement("div")
      p.id = this.props.portalId
      document.body.appendChild(p)
    }
    this.portalElement = p
    this.componentDidUpdate()
  },
  componentWillUnmount() {
    document.body.removeChild(this.portalElement)
  },
  componentDidUpdate() {
    ReactDOM.render(
      <div {...this.props}>{this.props.children}</div>,
      this.portalElement
    )
  },
})

class Modal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      firstDisplay: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.firstDisplay && props.display) {
      return { firstDisplay: true }
    }
    return null
  }

  componentWillUnmount() {
    document.getElementsByTagName("html")[0].classList.remove(styles.modalOpen)
  }
  render() {
    let { display, className, children, close } = this.props
    // disable scrolling when modal is open
    // doesn't disable scrolling on mobile, but looking
    // at other examples (bootstrap modal) they don't
    // seem to care about this either
    if (typeof document !== "undefined") {
      if (this.props.display)
        document.getElementsByTagName("html")[0].classList.add(styles.modalOpen)
      else
        document
          .getElementsByTagName("html")[0]
          .classList.remove(styles.modalOpen)
    }
    return (
      <Portal>
        <ThemeProvider theme={theme}>
          <div
            aria-hidden={!display}
            className={classNames(styles.container, {
              [styles.hidden]: !display,
              [styles.animate]: this.state.firstDisplay,
            })}
          >
            <div className={styles.overlay} />
            <div className={styles.scroll} onClick={close}>
              <div className={styles.wrapper}>
                <div
                  onClick={close}
                  className={classNames(className, styles.modal)}
                >
                  {children}
                  <button
                    aria-label="Close"
                    onClick={close}
                    className={styles.close}
                  >
                    <CloseIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ThemeProvider>
      </Portal>
    )
  }
}

export default Modal
