import React, { useState, useEffect, useRef } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import Arrow from "images/portfolio-carousel-arrow.svg"
import LogoItem from "components/molecules/LogoItem"
import Box from "components/atoms/Box"

import styles from "styles/components/molecules/portfolio-carousel.module.scss"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1100 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1100, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
}

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest
  return (
    <div className="brand-carousel-button-group">
      <button
        aria-label="Move Carousel Left"
        className={cx("leftArrow", { disable: currentSlide === 0 })}
        onClick={() => previous()}
      >
        <Arrow />
      </button>
      <button
        aria-label="Move Carousel Right"
        className={cx("rightArrow", {
          disable: currentSlide === totalItems - slidesToShow,
        })}
        onClick={() => next()}
      >
        <Arrow />
      </button>
    </div>
  )
}

const PortfolioCarousel = ({ delay, companies }) => {
  const [autoPlay, setAutoPlay] = useState(true)
  const autoPlaySpeed = delay / 2 // for whatever reason seems like this needs to be half the desired value to work correctly

  const timeout = useRef()
  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current)
    }
  }, [])

  return (
    <div className={styles.carouselWrapper}>
      <Carousel
        responsive={responsive}
        containerClass="brand-carousel-container"
        arrows={false}
        infinite
        autoPlay={autoPlay}
        autoPlaySpeed={autoPlaySpeed}
        afterChange={() => {
          // workaround to reset autoplay timer when user changes the active slide manually
          setAutoPlay(false)
          timeout.current = setTimeout(() => setAutoPlay(true), autoPlaySpeed)
        }}
        draggable={false}
        swipeable={true}
        minimumTouchDrag={10}
        customButtonGroup={<ButtonGroup />}
        renderButtonGroupOutside
        itemClass="brand-carousel-item"
      >
        {companies.map((company, i) => {
          return (
            <Box width={{ _: "260px", xs: "304px" }}>
              <LogoItem key={`${i}${company.name}`} {...company} />
            </Box>
          )
        })}
      </Carousel>
    </div>
  )
}

export default PortfolioCarousel
