import React from "react"
import Img from "gatsby-image"

import Reveal from "components/utils/Reveal"
import Box from "components/atoms/Box"
import FlexGrid from "components/atoms/FlexGrid"

export const ImageCard = ({
  image,
  url,
  right,
  isImageAboveTextCard,
  ...rest
}) => {
  const left = !right

  let top
  if (right) {
    if (isImageAboveTextCard) {
      top = { _: -10, md: -80, lg: 40 }
    } else {
      top = { _: -20, sm: -80, lg: 40 }
    }
  } else {
    if (isImageAboveTextCard) {
      top = { _: -10, lg: -40 }
    } else {
      top = { _: -20, sm: -80, lg: -40 }
    }
  }

  return (
    <Box position="relative" {...rest}>
      <Reveal effect={right ? "fade-right" : "fade-left"}>
        <Box
          left={
            right && {
              _: "8%",
              sm: "30%",
              md: isImageAboveTextCard ? "35%" : "30%",
              lg: "-5%",
            }
          }
          right={left && { _: "8%", sm: "10%", lg: "-10%" }}
          top={top}
          mb={right ? { _: -20, sm: -80, lg: 0 } : { _: -20, sm: -80, lg: 0 }}
          width={{ _: "100%", sm: "80%", lg: "100%" }}
          position="relative"
          height={{ _: "200px", xs: "340px" }}
        >
          {url && <img width="100%" src={url} />}
          {image && (
            <Img
              fluid={image.fluid}
              alt={image.description}
              style={{ height: "100%" }}
            />
          )}
        </Box>
      </Reveal>
    </Box>
  )
}

export const TextCard = ({ children, bg, right, ...rest }) => {
  return (
    <Box width="100%" zIndex={2} position="relative" {...rest}>
      <Box
        width={{ _: "100%", lg: "110%" }}
        zIndex={2}
        position="relative"
        padding={{ _: 5, md: 6 }}
        bg={bg}
        boxShadow={"0 6px 40px rgba(52,72,107,0.13)"}
        maxWidth="800px"
      >
        {children}
      </Box>
    </Box>
  )
}

export const OverlappingCardsContainer = ({ children, textRight, ...rest }) => {
  return (
    <FlexGrid
      // gutter={{ _: 0, lg: 8 }}
      gutter={0}
      columns={{ _: 1, lg: 2 }}
      flexDirection={textRight ? "row-reverse" : "row"}
      alignItems="flex-start"
      justifyContent="flex-start"
      {...rest}
    >
      {children}
    </FlexGrid>
  )
}
