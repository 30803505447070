import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import LinkedInLogo from "images/linked-in-icon.svg"

import Reveal from "components/utils/Reveal"

import styled from "styled-components"
import SectionStyling from "components/atoms/SectionStyling"
import StandardPadding from "components/atoms/StandardPadding"
import Box from "components/atoms/Box"
import FlexGrid from "components/atoms/FlexGrid"
import SectionText from "components/molecules/SectionText"
import Button from "components/atoms/Button"
import CircleLogo from "components/atoms/CircleLogo"
import PortfolioCarousel from "components/molecules/PortfolioCarousel"
import {
  OverlappingCardsContainer,
  TextCard,
  ImageCard,
} from "components/molecules/OverlappingCards"

const StyledImg = styled(Img)`
  border-radius: 100%;
  margin-left: 25%;
`

const LogoWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
`

const StyledAnchor = styled.a`
  transition: opacity 0.1s ease;
  &:hover {
    opacity: 0.5;
  }
`

const ImageTextContainer = ({
  isHero,
  sectionStyling,
  portfolioCompanies,
  isTeamMember,
  children,
}) => (
  <SectionStyling
    minHeight={!isTeamMember && isHero}
    first={isHero}
    {...sectionStyling}
  >
    <StandardPadding
      overflow="hidden"
      py={portfolioCompanies || isTeamMember ? { _: 6, lg: 8 } : undefined}
    >
      {children}
    </StandardPadding>
    {portfolioCompanies && (
      <Box
        position="relative"
        top={{ _: "-50px", lg: "-80px" }}
        pb="0"
        overflow="hidden"
      >
        <PortfolioCarousel companies={portfolioCompanies} delay={3000} />
      </Box>
    )}
  </SectionStyling>
)

const TextGroup = ({
  white,
  largeText,
  sectionText,
  button,
  linkedInLink,
  buttonColor,
  buttonMarginTop,
}) => (
  <>
    <SectionText white={white} variant={largeText && "h1"} {...sectionText} />
    {button && (
      <Button color={buttonColor} mt={buttonMarginTop || "14px"} {...button} />
    )}
    {linkedInLink && (
      <Box mt={6}>
        <StyledAnchor
          href={linkedInLink}
          target="_blank"
          rel="noreferrer noopener"
        >
          <LinkedInLogo />
        </StyledAnchor>
      </Box>
    )}
  </>
)

const ImageText = ({
  sectionStyling,
  sectionText,
  button,
  linkedInLink,
  image,
  imageLocation,
  imageLocationMobile,
  overlaidImage,
  textLayout,
  textCardBackground,
  portfolioCompanies,
  isTextCardBackgroundDark,
  isImageAboveTextCard,
  isTeamMember,
  idx,
}) => {
  const isHero = idx === 0
  const isDarkBg = sectionStyling.isDarkBackground

  const reverse = imageLocation === "left"

  const largeText = isHero && !image
  const flexDirection = {
    _: imageLocationMobile === "bottom" ? "column" : "column-reverse",
    lg: reverse ? "row-reverse" : "row",
  }
  const isCard = textLayout === "card"

  let buttonColor = "skyBlue"
  if (isCard) {
    if (textCardBackground) {
      switch (textCardBackground) {
        case "skyBlue":
          buttonColor = "white"
          break
        case "white":
          buttonColor = "skyBlue"
          break
        case "darkBlue":
          buttonColor = "teal"
          break
        default:
          buttonColor = "skyBlue"
      }
    }
    return (
      <ImageTextContainer
        isHero={isHero}
        sectionStyling={sectionStyling}
        portfolioCompanies={portfolioCompanies}
        isTeamMember={isTeamMember}
      >
        <OverlappingCardsContainer>
          <TextCard
            zIndex={isImageAboveTextCard ? 1 : 2}
            bg={textCardBackground}
          >
            <Box pr={isImageAboveTextCard ? { _: 0, lg: "50px" } : 0}>
              <TextGroup
                buttonMarginTop="80px"
                white={isTextCardBackgroundDark}
                largeText={largeText}
                sectionText={sectionText}
                button={button}
                buttonColor={buttonColor}
                linkedInLink={linkedInLink}
              />
            </Box>
          </TextCard>
          <ImageCard
            zIndex={isImageAboveTextCard ? 2 : 1}
            isImageAboveTextCard={isImageAboveTextCard}
            right
            image={image}
          />
        </OverlappingCardsContainer>
      </ImageTextContainer>
    )
  }

  if (isDarkBg) {
    buttonColor = "teal"
  } else {
    buttonColor = "skyBlue"
  }
  return (
    <ImageTextContainer
      isHero={isHero}
      sectionStyling={sectionStyling}
      portfolioCompanies={portfolioCompanies}
      isTeamMember={isTeamMember}
    >
      <FlexGrid
        gutter={{ _: 6, lg: 8 }}
        columns={{ _: 1, lg: image ? 2 : 1 }}
        flexDirection={flexDirection}
        alignItems={isCard ? "flex-start" : "center"}
        justifyContent={isHero && !image ? "flex-end" : "flex-start"}
      >
        <Box zIndex={2} position="relative">
          <Reveal>
            <Box width={"100%"} zIndex={2} position="relative" maxWidth="800px">
              <TextGroup
                white={isDarkBg}
                largeText={largeText}
                sectionText={sectionText}
                button={button}
                buttonColor={buttonColor}
                linkedInLink={linkedInLink}
              />
            </Box>
          </Reveal>
        </Box>
        {image && (
          <Reveal>
            <Box
              width="100%"
              maxWidth="500px"
              margin="0 auto"
              position="relative"
            >
              {!overlaidImage && (
                <Img fluid={image.fluid} alt={image.description} />
              )}
              {overlaidImage && (
                <>
                  <StyledImg fluid={image.fluid} alt={image.description} />
                  <LogoWrapper>
                    <CircleLogo logo={overlaidImage} />
                  </LogoWrapper>
                </>
              )}
            </Box>
          </Reveal>
        )}
      </FlexGrid>
    </ImageTextContainer>
  )
}

export default ImageText

// ImageText.propTypes = {
//   section: PropTypes.shape(Section.strapiProps).isRequired,
//   sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
//   text: PropTypes.string,
//   button: PropTypes.shape(Button.strapiProps),
//   image: PropTypes.object,
//   imageLocation: PropTypes.oneOf(["right", "left"]),
// }

export const query = graphql`
  fragment ImageText on ContentfulImageText {
    sectionStyling {
      ...SectionStyling
    }
    sectionText {
      ...SectionText
    }
    button {
      ...Button
    }
    image {
      description
      fluid(maxWidth: 1000, quality: 100) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    portfolioCompanies {
      ...PortfolioCompany
    }
    imageLocation
    imageLocationMobile
    textLayout
    textCardBackground
    isTextCardBackgroundDark
    isImageAboveTextCard
  }
`
